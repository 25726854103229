import React from 'react'
import injectSheet from 'react-jss'

import JumboBackground from '../components/jumbo-background'
import HeaderText from '../components/header-text'
import CardBox from '../components/card-box'
import HalfCard from '../components/half-card'
import Layout from '../components/layout'

import Abbotsford8 from '../images/Abbotsford/Abbotsford8_dark.jpg'

const styles = {
  maps: {
    border: 0,
    width: 'calc(50vw - 6em)',
    height: 'calc(50vw - 6em)',
    maxWidth: 'calc(500px - 2em)',
    maxHeight: 'calc(500px - 2em)',
    marginBottom: '1em',
    '@media (max-width: 767px)': {
      width: 'calc(100vw - 6em)',
      height: 'calc(100vw - 6em)'
    }
  },
  dark: {
    color: 'black'
  },
  contactdetails: {
    '@media (min-width: 768px)': {
        paddingTop: '27vh'
    }
  }
}

const ContactPage = ({location, classes}) => {
  return(
    <Layout path={location.pathname}>
      <JumboBackground background={Abbotsford8}/>
      <CardBox higher center>
        <HalfCard transparent>
          <div className={classes.contactdetails}>
          <HeaderText>Contact Us</HeaderText>
          <h3>Phone: 03 9737 1800</h3>
          <h3>Mobile: 0419 871 193</h3>
          <br/>
          <h3 style={{fontWeight: 'bold'}}>David Eastwood</h3>
          <h3>david@vpm.com.au</h3>
          </div>
        </HalfCard>
        <HalfCard>
          <iframe
          className={classes.maps}
          frameBorder="0"
          src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJn5RShicv1moRfrgpHBVjuMQ&key=AIzaSyDykVpJjVyqVfnDz547OOHxs9SBytlVwCg"
          allowFullScreen>
        </iframe>
        <HeaderText dark>Our Office</HeaderText>
        <h3 className={classes.dark}>13 Clancy Road</h3>
        <h3 className={classes.dark}>Mount Evelyn</h3>
        <h3 className={classes.dark}>VIC, 3796</h3>
        </HalfCard>
      </CardBox>
    </Layout>
  )
}

export default injectSheet(styles)(ContactPage)
