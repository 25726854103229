import React, {Component} from 'react'
import injectSheet from 'react-jss'

const styles = {
  halfcard: {
    width: 'calc(50vw - 4em)',
    margin: '1em',
    padding: '1em',
    minWidth: '160px',
    maxWidth: '500px',
    '@media (max-width: 767px)': {
      width: 'calc(100vw - 4em)'
    }
  },
  transparent: {},
  solid: {
    backgroundColor: 'white',
    borderTop: '8px solid #F37B1D'
  }
}


class HalfCard extends Component {
  render() {
    const {classes, children, transparent} = this.props
    return(
      <div className={classes.halfcard + ' ' + (transparent ? classes.transparent : classes.solid)}>
        {children}
      </div>
    )
  }
}

export default injectSheet(styles)(HalfCard)
