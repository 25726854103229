import React, {Component} from 'react'
import injectSheet from 'react-jss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import HeaderText from './header-text'
import Button from './button'

const styles = {
  container: {
    flexFlow: 'column',
    position: 'relative',
    zIndex: '10',
    marginTop: 'calc(-20vh - 256px)',
    margin: '0em 1em 1em 1em',
    '@media (max-width: 767px)': {
      marginTop: 'calc(-20vh - 198px)',
    },
    '@media (min-width: 1500px)': {
      justifyContent: 'center'
    }
  },
  higher: {
    flexFlow: 'column',
    position: 'relative',
    zIndex: '10',
    marginTop: 'calc(-35vh - 256px)',
    margin: '0em 1em 1em 1em',
    minHeight: '50vh',
    '@media (max-width: 767px)': {
      marginTop: 'calc(-45vh - 198px)',
    }
  },
  cardbox: {
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: '30vh',
  },
  cardboxCenter: {
    justifyContent: 'center'
  },
  jumbotitle: {
    display: 'flex',
    flexFlow: 'column',
    margin: '1em 2em',
    '@media (min-width: 1400px)': {
        width: '1200px'
    }
	},
  jumbotitleCenter: {
    width: '1200px'
  },
  spacer: {
		height: '2em',
		'@media (max-width: 767px)': {
			height: '1em'
		}
  },
  titlebox: {
    display: 'flex',
    justifyContent: 'center'
  }
}

class CardBox extends Component {
  render() {
    const {classes, children, title, subtitle, button, btnArrow, btnOnClick, noSpacer, higher, center} = this.props
    return(
      <div className={(higher ? classes.higher : classes.container)}>
      <div className={(center ? classes.titlebox : '')}>
        <div className={classes.jumbotitle + ' ' + (center ? classes.jumbotitleCenter : '')}>
            {(title ? <HeaderText noSpacer={noSpacer}>{title}</HeaderText> : '')}
            <h3>{subtitle}</h3>
            {(button ? <span><div className={classes.spacer}></div><Button onClick={btnOnClick}>{button} {(btnArrow ? <span>&nbsp;&nbsp;<FontAwesomeIcon icon="chevron-right"/></span> : '' )}</Button></span> : '' )}
        </div>
      </div>
      <div className={classes.cardbox + ' ' + (center ? classes.cardboxCenter : '')}>
      {children}
      </div>
      </div>
    )
  }
}

export default injectSheet(styles)(CardBox)
